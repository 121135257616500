<template>
  <div class="container">
    <h1>{{ org.name }}</h1>
    <p class="caption">{{ org.type }}</p>
    <div class="org-location caption">
      <v-icon small color="inqliDarkGray">mdi-map-marker</v-icon>
      {{ orgLocation }}
    </div>
    <div class="org-image-container mt-5">
      <v-img
        contain
        class="mb-5 org-logo"
        :src="org.logo_url"
        alt="Organization logo"
        v-if="org.logo_url"
      />
      <div class="org-logo-placeholder mb-5" v-else>
        <v-avatar color="inqliPurple" size="100">
          <span class="white--text">{{ org.name }}</span>
        </v-avatar>
      </div>
      <div class="org-description mt-3">
        {{ org.description }}
      </div>
      <div class="org-button-container">
        <div>
          <inqli-button
            color="inqliPurple"
            :href="org.website_url"
            target="_blank"
            class="org-button"
            width="150"
          >
            <v-icon left>mdi-web</v-icon>
            Website
          </inqli-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Country, State } from "country-state-city";
import InqliButton from "../../app/components/buttons/Button.vue";

export default {
  name: "OrgDetailCard",
  props: {
    org: {
      type: Object,
      required: true,
    },
  },
  components: {
    "inqli-button": InqliButton,
  },
  computed: {
    orgLocation() {
      const location = this.org.location;
      if (!location) return "";
      const msg = [];
      if (location.city) {
        msg.push(location.city);
      }
      if (location.state && location.country) {
        const state = State.getStateByCodeAndCountry(
          location.state,
          location.country
        ).name;
        msg.push(state);
      }
      if (location.country) {
        const country = Country.getCountryByCode(location.country).name;
        msg.push(country);
      }
      return msg.join(", ");
    },
    orgInsightRoute() {
      return `/organizations/insights/${this.org.id}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.caption {
  color: $inqliDarkGray;
  font-weight: 500;
}
.org-logo-placeholder {
  margin: auto;
}
.org-logo {
  width: 100%;
  max-width: 200px;
  margin: auto;
  border-radius: 16px;
}
.org-description {
  white-space: break-spaces;
}
.org-button-container {
  display: flex;
  justify-content: space-around;
  width: 300px;
  margin: auto;
  margin-top: 20px;
}
.org-location {
  margin-top: -15px;
}
</style>
