var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("h1", [_vm._v(_vm._s(_vm.org.name))]),
    _c("p", { staticClass: "caption" }, [_vm._v(_vm._s(_vm.org.type))]),
    _c(
      "div",
      { staticClass: "org-location caption" },
      [
        _c("v-icon", { attrs: { small: "", color: "inqliDarkGray" } }, [
          _vm._v("mdi-map-marker")
        ]),
        _vm._v(" " + _vm._s(_vm.orgLocation) + " ")
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "org-image-container mt-5" },
      [
        _vm.org.logo_url
          ? _c("v-img", {
              staticClass: "mb-5 org-logo",
              attrs: {
                contain: "",
                src: _vm.org.logo_url,
                alt: "Organization logo"
              }
            })
          : _c(
              "div",
              { staticClass: "org-logo-placeholder mb-5" },
              [
                _c(
                  "v-avatar",
                  { attrs: { color: "inqliPurple", size: "100" } },
                  [
                    _c("span", { staticClass: "white--text" }, [
                      _vm._v(_vm._s(_vm.org.name))
                    ])
                  ]
                )
              ],
              1
            ),
        _c("div", { staticClass: "org-description mt-3" }, [
          _vm._v(" " + _vm._s(_vm.org.description) + " ")
        ]),
        _c("div", { staticClass: "org-button-container" }, [
          _c(
            "div",
            [
              _c(
                "inqli-button",
                {
                  staticClass: "org-button",
                  attrs: {
                    color: "inqliPurple",
                    href: _vm.org.website_url,
                    target: "_blank",
                    width: "150"
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-web")]),
                  _vm._v(" Website ")
                ],
                1
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }